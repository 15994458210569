<!-- eslint-disable -->
<template>
<v-container
    id="dashboard"
    fluid
    tag="section"
  >
   <v-row class="text-left">
      <v-col cols="12" md="6"><span class="headline">eOffice KSK-Group</span></v-col>
      <v-col cols="12" md="6" class="text-right" style="vertical-align:middle;padding:10px">
        <a href="/#/login">
            <v-btn color="blue lighten-1" >Sign In</v-btn>
          </a>
      </v-col>
    </v-row>
    <p style="border-top:4px solid #4DA521;"></p>
    <br>
    <v-row
        align="center"
        justify="center"
    >

        <v-col
            cols="6"
            md="6"
            class="text-left"
        >

            <v-card class="mx-auto px-3 py-3">
              <p class="display-2">Set New Password</p>
              <span class="subtitle">Please enter your NEW PASSWORD:</span>
              <v-form ref="form" v-model="valid">

                    <v-row>
                      
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                            v-model="resetForm.password"
                            label="Password"
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass = !showPass"
                            :rules="[rules.required, rules.min]"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                      >
                      <v-text-field
                            v-model="resetForm.passwordAgain"
                            label="Password (Again)"
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass = !showPass"
                            :rules="[passwordConfirmationRule]"
                        ></v-text-field>

                      </v-col>
                    </v-row>
                      
                    <v-divider></v-divider>
                      <v-spacer></v-spacer>
                      <div class="text-right">
                        <v-btn
                                    :disabled="!valid || resetForm.password.length <= 0"
                                    color="success"
                                    class="mr-4"
                                    @click="handleReset()"
                                >
                                Simpan Password
                                </v-btn>
                      </div>
              </v-form>
            </v-card>
            
        </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>
    
  </v-container>
</template>
<script>
  import secureStorage from '@/utils/secureStorage'
  import { _GLOBAL } from '@/api/server'
  import moment from 'moment'

  import { resetPassword } from '@/api/login'


  export default {
    name: 'authlogin',
    data() {
      return {
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        valid: false,
        showPass: false,
        vivus: '',
        toast: { show: false, color:'green', text:'', timeout: 2000 },
        isLoading: false,
        errType: 0,
        resetForm: {
          code: this.$route.params.code,
          password: '',
          passwordAgain: ''
        },
        passwordType: 'password',
        passwordTypeAgain: 'password',
        loading: false,
        showDialog: false,
        redirect: undefined
      }
    },
    created () {
      
    },
    destroyed () {
    },
    mounted () {
      
    },
    computed: {
      passwordConfirmationRule() {
        return () => (this.resetForm.password === this.resetForm.passwordAgain) || 'Password must match'
      }
    },
    methods:{
      handleReset() {
        const vm = this
        //console.log(this.$route.params)
        //console.log(this.resetForm)
        vm.isLoading = true
        resetPassword(vm.resetForm.code, vm.resetForm.password, vm.resetForm.passwordAgain)
        .then(response => {
          vm.toast = {
              show: true, color:'green', text: 'Save New Password was successfully!', timeout: 2000
          }
          vm.isLoading = false
          this.$router.push('/login')
        }).catch(error => {
          console.log(error)
          vm.toast = {
              show: true, color:'red', text: 'Reset Password was failed!', timeout: 2000
          }
          vm.isLoading = false
        })
        
        
      }
  }
}
</script>
<style>
</style>
<!-- eslint-enable -->